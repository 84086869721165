<template>
    <WitModal
        v-model="showModal"
        title="Configure dates"
        variant="warning"
        disableDBlock
        size="lg"
        customClass="workflow-builder-pass__modal"
        @input="onModalChange"
    >
        <!-- <b-form-group label="Choose your Pass state" :label-cols="2" :horizontal="true" class="radio-form-label"> -->
        <b-row>
            <!-- <b-col md="2" /> -->
            <b-col md="12">
                <b-form-radio-group
                    id="pass-form-radio-group"
                    class="radio-form-radio-group"
                    v-model="passForm.selected"
                    :options="passForm.options"
                    @input="previewPassState"
                />
            </b-col>
        </b-row>
        <!-- </b-form-group> -->

        <b-row class="radio-form-row">
            <b-col md="2" />
            <b-col md="9" v-if="passForm.selected === 'Last X Days'">
                <b-row>
                    Current Date -
                    <b-col md="3">
                        <b-form-input
                            v-model="$v.passForm.value.lastXDays.$model"
                            placeholder="14"
                            :number="true"
                            @input="previewPassState"
                            id="step-modal-pass-lastxdays"
                        ></b-form-input
                    ></b-col>
                    Days
                </b-row>
                <Feedback :state="validateRef('passForm.value.lastXDays')" invalid="This field is required"></Feedback>
            </b-col>
            <b-col md="9" v-if="passForm.selected === 'Dynamic Date Range'">
                <b-row>
                    <b-col md="2">
                        <strong>Start:</strong>
                    </b-col>
                    Current Date -
                    <b-col md="3">
                        <b-form-input
                            v-model="$v.passForm.value.dynamicDateRange.start.$model"
                            placeholder="8"
                            :number="true"
                            @input="previewPassState"
                            id="step-modal-pass-dynamic-start"
                        ></b-form-input
                    ></b-col>
                    Days
                </b-row>
                <b-row>
                    <b-col md="2">
                        <strong>End:</strong>
                    </b-col>
                    Current Date -
                    <b-col md="3">
                        <b-form-input
                            v-model="$v.passForm.value.dynamicDateRange.end.$model"
                            placeholder="3"
                            :number="true"
                            @input="previewPassState"
                            id="step-modal-pass-dynamic-end"
                        ></b-form-input
                    ></b-col>
                    Days
                </b-row>
                <Feedback
                    :state="validateRef('passForm.value.dynamicDateRange')"
                    invalid="These fields are required. Start must be an older date than end"
                ></Feedback>
            </b-col>
            <b-col md="9" v-if="passForm.selected === 'Static Date Range'">
                <ScheduleBackfill
                    v-model="$v.passForm.value.staticDateRange.$model"
                    @input="previewPassState"
                ></ScheduleBackfill>
                <Feedback
                    :state="validateRef('passForm.value.staticDateRange')"
                    invalid="These fields are required. Start must be an older date than end"
                ></Feedback>
            </b-col>
            <b-col md="9" v-if="passForm.selected === 'Specified Date Range'">
                <b-row>
                    <b-col md="11">
                        <wit-input-group
                            v-model="$v.passForm.value.specifiedDateRange.dates.$model"
                            type="textarea"
                            input-col="12"
                            min-rows="6"
                            placeholder="Paste dates (comma separated dates in format YYYY-MM-DD)"
                            id="step-modal-pass-specified-date-range"
                        ></wit-input-group>
                    </b-col>

                    <b-col>
                        <i
                            v-b-tooltip.hover
                            title="Comma separated dates in format YYYY-MM-DD"
                            class="icon fa fa-question-circle"
                        />
                    </b-col>
                </b-row>
                <Feedback
                    :state="validateRef('passForm.value.specifiedDateRange')"
                    invalid="These field is required. Pass dates separated by comma and in accepted format"
                ></Feedback>
            </b-col>
        </b-row>
        <b-form-group
            v-if="passForm.preview.message"
            label="Dates You'll Process"
            :label-cols="2"
            :horizontal="true"
            class="preview-pass-state-form-group"
        >
            <b-row>
                <b-col md="10">
                    <b-alert
                        :variant="passForm.preview.success ? 'success' : 'danger'"
                        show
                        class="preview-pass-state-alert"
                    >
                        <span v-if="passForm.preview.success"
                            ><span
                                v-for="(item, index) in passForm.preview.message"
                                v-bind:key="index"
                                v-bind:class="{
                                    'preview-item-today': isToday(item),
                                    'preview-item-greater-than-today': isGreaterThanToday(item),
                                }"
                                >{{ index === passForm.preview.message.length - 1 ? `${item}` : `${item}, ` }}</span
                            ></span
                        >
                    </b-alert>
                </b-col>
            </b-row>
        </b-form-group>

        <b-row>
            <b-col>
                <b-button @click="finish" :disabled="isPageChecked && $v.$invalid" variant="primary">
                    <i class="icon-check"></i>
                    <span> Save</span>
                </b-button>
            </b-col>
        </b-row>
    </WitModal>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {requiredIf} from 'vuelidate/lib/validators'

import WitModal from '@/components/Modals/WitModal.vue'
import Feedback from '@/components/Feedback.vue'
import ScheduleBackfill from '@/components/ScheduleBackfill.vue'
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'

export default {
    components: {
        WitModal,
        Feedback,
        ScheduleBackfill,
        WitInputGroup,
    },
    mixins: [validationMixin],
    props: {
        value: {
            type: Array,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            passForm: {
                selected: 'Today',
                options: ['Today', 'Last X Days', 'Dynamic Date Range', 'Static Date Range', 'Specified Date Range'],
                value: {
                    lastXDays: null,
                    dynamicDateRange: {
                        start: null,
                        end: null,
                    },
                    staticDateRange: {
                        start: '',
                        end: '',
                    },
                    specifiedDateRange: {
                        dates: '',
                    },
                },
                preview: {
                    message: '',
                    success: true,
                },
            },
            showModal: false,
            isPageChecked: false,
        }
    },
    validations: {
        passForm: {
            value: {
                lastXDays: {
                    required: requiredIf(function () {
                        return this.passForm.selected === 'Last X Days'
                    }),
                },
                dynamicDateRange: {
                    start: {
                        required: requiredIf(function () {
                            return this.passForm.selected === 'Dynamic Date Range'
                        }),
                    },
                    end: {
                        required: requiredIf(function () {
                            return this.passForm.selected === 'Dynamic Date Range'
                        }),
                    },
                    valid: function () {
                        return this.passForm.value.dynamicDateRange.start >= this.passForm.value.dynamicDateRange.end
                    },
                },
                staticDateRange: {
                    start: {
                        required: requiredIf(function () {
                            return this.passForm.selected === 'Static Date Range'
                        }),
                    },
                    end: {
                        required: requiredIf(function () {
                            return this.passForm.selected === 'Static Date Range'
                        }),
                    },
                    valid: function () {
                        return this.passForm.value.staticDateRange.start <= this.passForm.value.staticDateRange.end
                    },
                },
                specifiedDateRange: {
                    dates: {
                        mustBeDate: function (value) {
                            if (this.passForm.selected !== 'Specified Date Range') {
                                return true
                            }

                            const dates = value.split(',')

                            const validations = dates.map((date) => {
                                return (
                                    new Date(date).getTime() === new Date(date).getTime() &&
                                    date.length === 10 &&
                                    date.includes('-')
                                )
                            })

                            return validations.every((date) => date === true)
                        },
                    },
                },
            },
        },
    },
    computed: {
        passValue() {
            const {value} = this.passForm

            let computed = []
            if (this.passForm.selected === 'Last X Days') {
                computed.push({key: 'lastXDays', value: value.lastXDays})
            }
            if (this.passForm.selected === 'Dynamic Date Range') {
                computed.push({key: 'dynamicDateRange', value: value.dynamicDateRange})
            }
            if (this.passForm.selected === 'Static Date Range') {
                computed.push({key: 'staticDateRange', value: value.staticDateRange})
            }
            if (this.passForm.selected === 'Specified Date Range') {
                computed.push({key: 'specifiedDateRange', value: value.specifiedDateRange.dates})
            }

            return computed
        },
    },
    created() {
        this.init()
    },
    watch: {
        show() {
            this.init()
            this.isPageChecked = false
            this.$v.$reset()
        },
    },
    methods: {
        init() {
            if (this.value && Array.isArray(this.value)) {
                if (this.value.length) {
                    const [{key, value}] = this.value

                    if (key === 'lastXDays') {
                        this.passForm.selected = 'Last X Days'
                        this.passForm.value.lastXDays = value
                    }
                    if (key === 'dynamicDateRange') {
                        this.passForm.selected = 'Dynamic Date Range'
                        this.passForm.value.dynamicDateRange = value
                    }
                    if (key === 'staticDateRange') {
                        this.passForm.selected = 'Static Date Range'
                        this.passForm.value.staticDateRange = value
                    }
                    if (key === 'specifiedDateRange') {
                        this.passForm.selected = 'Specified Date Range'
                        this.passForm.value.specifiedDateRange.dates = value
                    }
                } else {
                    this.passForm.selected = 'Today'
                }
            }

            this.showModal = this.show
        },

        async previewPassState() {
            this.passForm.preview = {
                message: '',
                success: true,
            }

            clearTimeout(this.passForm.timeout)
            if (!this.$v.passForm.value.$invalid && this.passForm.selected && this.passForm.selected !== 'Today') {
                this.passForm.timeout = setTimeout(async () => {
                    const [computed] = this.passValue

                    try {
                        const previewResponse = await this.axios.post(
                            `${process.env.VUE_APP_NODE_API_HOST}/workflow/passDate`,
                            computed
                        )
                        this.passForm.preview = {
                            message: previewResponse.data.data,
                            success: true,
                        }
                    } catch (e) {
                        this.passForm.preview = {
                            message: `We couldn't prepare a preview :(`,
                            success: false,
                        }
                        this.$errorHandler.report(e, 'Could not fetch pass state preview')
                    }
                }, 1000)
            }
        },

        isToday(value) {
            const today = new Date()
            const someDate = new Date(value)
            return (
                someDate.getDate() == today.getDate() &&
                someDate.getMonth() == today.getMonth() &&
                someDate.getFullYear() == today.getFullYear()
            )
        },

        isGreaterThanToday(value) {
            const today = new Date()
            const someDate = new Date(value)

            return (
                (someDate.getDate() > today.getDate() &&
                    someDate.getMonth() === today.getMonth() &&
                    someDate.getFullYear() >= today.getFullYear()) ||
                (someDate.getMonth() > today.getMonth() && someDate.getFullYear() === today.getFullYear()) ||
                someDate.getFullYear() > today.getFullYear()
            )
        },

        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty || this.isPageChecked ? !validateField.$invalid : null
        },

        emitInput() {
            this.$emit('input', this.passValue)
        },

        onModalChange() {
            this.$emit('update:show', this.showModal)
        },

        async finish() {
            this.isPageChecked = true
            await this.$nextTick()

            if (!this.$v.$invalid) {
                this.emitInput()

                this.showModal = false
                this.onModalChange()
            }
        },
    },
}
</script>

<style></style>
